<template>
  <div class="content-page">
    <div>
      <InputOTP 
        @submitValidateOTP="submitValidateOTP" 
        :type="2"
        @clickChangePhone="clickChangePhone"
      />
    </div>
    <ModalError ref="modalError" :text="successMessage" />
    <ModalAlert ref="modalAlert" :text="successMessage" @hide="hide"/>
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
import ModalError from "@/components/alert-modal/ModalError";
import ModalAlert from "@/components/alert-modal/ModalAlert.vue";
import InputOTP from "@/components/input/InputOTP";
import {
  required,
  minLength,
  integer
} from "vuelidate/lib/validators";
export default {
  components:{
    OtherLoading,
    ModalError,
    ModalAlert,
    InputOTP
  },
  data(){
    return{
      ref_code : {},
      isLoading : false,
      form:{
        Telephone: this.$route.query.tel,
        RefCode : "",
        OTP : ""
      },
      successMessage:""
    }
  },
  methods: {
    hide(){
      this.$router.push('/profile');
    },
    submitValidateOTP: async function(val) {
      this.form = val;
      await this.$axios
      .post(`${process.env.VUE_APP_API}/api/v1/user/VerifyOTP`, this.form)
      .then(async data => {
        this.isLoading = false;
        if (data.result == 1) {
          this.changeTelephone();
        } else {
          this.successMessage = data.message;
          this.$refs.modalError.show();
        }
      });
    },
    async changeTelephone(){
      let reqTelephone = {
        telephone : this.$route.query.tel
      };
      await this.$axios
      .post(`${process.env.VUE_APP_API}/api/v1/user/ChangeTelephone`, reqTelephone)
      .then(async data => {
        this.isLoading = false;
        if (data.result == 1) {
          this.successMessage = "ทำรายการเปลี่ยนเบอร์โทรศัพท์เรียบร้อย";
          this.$refs.modalAlert.show();
        } else {
          this.successMessage = data.message;
          this.$refs.modalError.show();
        }
      });
    },
    clickChangePhone(val){
      this.$router.push('/telephone');
    }
  },
  validations() {
    return {
      form: {
        OTP: {
          minLength: minLength(6),
          integer,
          required
        }
      }
    };
  },
  onClickPage(){
    this.$router.push('/telephone');
  }
};
</script>
<style lang="scss" scoped>
.btn-otp{
  border-bottom: 1px solid #000;
  border-top: none;
  border-left: none;
  border-right: none;
  text-align: center;
  border-radius: 0px;
}
::v-deep .otp-input{
  width: 45px!important;
  font-size: 18px;
  height: 50px;
  background: transparent;
  text-align: center;
}

::v-deep .otp-input{
  @media (max-width: 767.98px) {
    width: 45px!important;
    font-size: 18px;
    height: 50px;
    background: transparent;
    text-align: center;
  }
}
.input-number{
  align-items: center;
  justify-content: center;
}
.bg-trans{
  background-color: transparent;
}
.content-page{
  margin-top: 80px;
  // min-height: 100vh;
}
</style>
